<template>
    <div>
        <h4>
            <b-img :src="getIconImageUrl()" width="50" :key="demoInfos.iconPath"/>
            Details for {{demoInfos.packageName}}</h4>
        <br/>

        <b-alert :variant="message.variant" :show="isNotNull(message.content)" class="mb-2">{{ message.content }}</b-alert>

        <b-form @submit="onSubmit" style="text-align: right;">

            <b-form-group id="input-group-2" label="Application Name:" label-for="input-2" label-cols="3">
                <b-form-input
                        id="input-2"
                        v-model="demoInfos.name"
                        placeholder="Enter name for the app"
                        required
                        disabled
                ></b-form-input>

                <b-form-text text-variant="secondary">* Mandatory (read from APK)</b-form-text>
            </b-form-group>

            <b-form-group id="input-group-3" label="Owner's email:" label-for="input-3" label-cols="3">
                <b-form-input
                        id="input-3"
                        v-model="demoInfos.ownerEmail"
                        placeholder="Enter your email"
                        required
                ></b-form-input>

                <b-form-text text-variant="danger">* Mandatory</b-form-text>
            </b-form-group>

            <b-form-group id="input-group-4" label="Tags:" label-for="input-4" label-cols="3">
                <b-form-tags
                        id="input-4"
                        v-model="demoInfos.tags"
                        input-id="tags-basic"
                        remove-on-delete
                        tag-variant="primary"
                        tag-pills
                        required
                ></b-form-tags>

                <b-form-text
                        text-variant="danger"
                >
                    * Be sure to add at least "yumi" or "valina" as a tag for you demonstration to be available in the terminal
                </b-form-text>
            </b-form-group>

            <b-form-group label="Groups for this demonstration:" label-for="groups" label-cols="3" v-if="isAdmin">
                <b-form-tags id="groups" class="text-left">
                    <template>
                        <ul v-if="selectedGroups.length > 0" class="list-inline mb-2">
                            <li v-for="group in selectedGroups" :key="group.id" class="list-inline-item">
                                <b-form-tag
                                        @remove="onRemoveGroup(group)"
                                        variant="primary"
                                        pill
                                >
                                    {{ group.name }}
                                </b-form-tag>
                            </li>
                        </ul>
                        <span v-else>
                        No group for this demonstration for the moment (only available on admin group)
                    </span>

                        <b-dropdown size="sm" variant="outline-primary" block menu-class="w-100" class="mt-2">
                            <template #button-content>
                                Choose group
                            </template>
                            <b-dropdown-form @submit.stop.prevent="() => {}">
                                <b-form-group
                                        label="Search groups"
                                        label-cols-md="auto"
                                        class="mb-0"
                                        label-size="sm"
                                        :description="searchDesc"
                                >
                                    <b-form-input
                                            v-model="search"
                                            type="search"
                                            size="sm"
                                            autocomplete="off"
                                    ></b-form-input>
                                </b-form-group>
                            </b-dropdown-form>

                            <b-dropdown-divider></b-dropdown-divider>

                            <b-dropdown-item-button
                                    v-for="group in availableGroups"
                                    :key="'dropdown_group_' + group.id"
                                    @click="onGroupSelected(group)"
                            >
                                {{ group.name }}
                            </b-dropdown-item-button>

                            <b-dropdown-text v-if="availableGroups.length === 0">
                                There are no groups available to select
                            </b-dropdown-text>
                        </b-dropdown>
                    </template>
                </b-form-tags>
            </b-form-group>

            <b-form-group
                    id="input-group-5"
                    label="Short description:"
                    label-for="input-5"
                    label-cols="3"
            >
                <b-form-input
                        id="input-5"
                        v-model="demoInfos.shortDesc"
                        placeholder="Enter a short description"
                        required
                ></b-form-input>

                <b-form-text text-variant="secondary">* Mandatory</b-form-text>
            </b-form-group>

            <b-form-group id="input-group-6" label="Detailed description:" label-for="input-6" label-cols="3" required>
                <b-form-textarea
                        id="input-6"
                        v-model="demoInfos.longDesc"
                        placeholder="Enter a detailed description of your demonstration ..."
                        rows="5"
                        max-rows="10"></b-form-textarea>

                <b-form-text text-variant="secondary">* Mandatory</b-form-text>
            </b-form-group>

            <b-form-group id="input-group-7" label="Path to documentation:" label-for="input-7" label-cols="3">
                <b-form-input id="input-7" v-model="demoInfos.docPath" placeholder="Link to the demonstration's documentation"></b-form-input>

                <b-form-text text-variant="secondary">
                    Make sure this URL is public &amp; requires no credentials
                </b-form-text>
            </b-form-group>

            <b-form-group id="input-group-8" label="Path to video:" label-for="input-8" label-cols="3">
                <b-form-input id="input-8" v-model="demoInfos.videoPath" placeholder="Link to a video about the demonstration"></b-form-input>

                <b-form-text text-variant="secondary">
                    This URL must lead to a public direct .mp4 file (no redirect, no credentials, no YouTube, ...)
                </b-form-text>
            </b-form-group>

            <br/>

            <b-button @click="showChangelogModal = true" variant="warning" class="mr-2">Update last changelog</b-button>
            <b-button v-b-modal.modal-delete-confirmation variant="danger" class="mr-2">Delete this demonstration</b-button>
            <b-button type="submit" variant="primary" v-if="!savePending">Save</b-button>
            <b-button variant="primary" disabled v-else>
                <b-spinner small></b-spinner>
                Saving ...
            </b-button>

        </b-form>

        <div>
            <b-modal id="modal-delete-confirmation" title="Oh, really ?" @ok="removeApp">Is this your very last word ?</b-modal>
        </div>

        <ChangelogModal :show="showChangelogModal" :demo="demoInfos" :onlyEdit="true" :latest="true" @closeChangelogModal="closeChangelogModal" :key="changelogModalKey"></ChangelogModal>
    </div>
</template>

<script>
	import DemonstrationsApi from "@/assets/js/DemonstrationsApi";
	import GroupsApi from "@/assets/js/GroupsApi";
	import {getConfig} from "@/assets/js/utils";
	import ChangelogModal from "../components/ChangelogModal";
	var app = getConfig();
	export default {
		name: "EditDemonstration",
		components: {
			ChangelogModal,
		},
		data() {
			return {
				demoInfos: {},
				groups: [],
				selectedGroups: [],
				search: '',
				message: {},
				isAdmin: localStorage.getItem("TDM_isAdmin") === "true",
				showChangelogModal: false,
				changelogModalKey: 0,
                savePending: false
			};
		},
		created(){
			this.retrieveDemo();
			this.retrieveAllGroups();
			this.retrieveDemoGroups();
			if(this.$route.params.fromUpload){
                this.showChangelogModal = true
			}
		},
		computed: {
			availableGroups() {
				const criteria = this.search.trim().toLowerCase();
				const groups = this.groups.filter(group => {
					let index = this.selectedGroups.findIndex(selectedGroup => selectedGroup.id === group.id);
					return index === -1
				});
				if (this.isNotNull(criteria)) {
					let groupList = groups.filter(group => group.name.toLowerCase().indexOf(criteria) > -1);
					return groupList.sort(this.orderAsc)
				}
				return groups.sort(this.orderAsc)
			},
			searchDesc() {
				if (this.isNotNull(this.search) && this. availableGroups.length === 0) {
					return 'There are no group matching your search'
				}
				return ''
			}
		},
		methods: {
			retrieveDemo() {
				if(this.isNotNull(this.$route.params.complexId)){
					DemonstrationsApi.get(this.$route.params.complexId)
						.then(response => {
							this.demoInfos = response.data;
							var tagsTemp = [];
							if(this.isNotNull(response.data.tags)){
								response.data.tags.forEach(tag => {
									tagsTemp.push(tag.name)
								});
                            }
							this.demoInfos.tags = tagsTemp
                            this.changelogModalKey++
						})
					/*.catch(error => {
						this.message = {
							variant: "danger",
                            content: error.response.data.message
                        }
					})*/
				}
			},
			retrieveDemoGroups() {
				DemonstrationsApi.getGroups(this.$route.params.complexId)
					.then(response => {
						this.selectedGroups = response.data;
						let indexOfAdmin = this.selectedGroups.findIndex(group => group.complexId === app.groupComplexId);
						this.selectedGroups.splice(indexOfAdmin, 1);
					})
					.catch(e => {
						console.log(e);
					});
			},
			retrieveAllGroups() {
				GroupsApi.getAll()
					.then(response => {
						this.groups = response.data;
						let indexOfAdmin = this.groups.findIndex(group => group.complexId === app.groupComplexId);
						this.groups.splice(indexOfAdmin, 1);
					})
					.catch(e => {
						console.log(e);
					});
			},
			onSubmit(event) {
				event.preventDefault();
				this.savePending = true;
				DemonstrationsApi.update(this.demoInfos)
					.then(response => {
						if(!response.data.error){
							this.saveGroups();
						}
					})
					.catch(e => {
						console.log(e);
					});
			},
            saveGroups(){
				if(this.selectedGroups.length > 0){
					GroupsApi.editGroupsList(this.demoInfos, this.selectedGroups)
						.then(response => {
							if(!response.data.error){
								this.message = {
									variant: "info",
									content: "Demonstration infos updated, redirecting to demonstration list..."
								};
								this.redirectToList(0);
							}
						})
						.catch(e => {
							console.log(e);
						});
                }else{
					this.message = {
						variant: "info",
						content: "Demonstration infos updated, redirecting to demonstration list..."
					};
					this.redirectToList(0);
                }
            },
			redirectToList(delay) {
				this.savePending = false;
				setTimeout(() => this.$router.push({name: "demonstrations"}), delay);
			},
			onGroupSelected(group) {
				this.selectedGroups.push(group);
				this.search = ''
			},
			onRemoveGroup(group){
				for(let i = 0; i < this.selectedGroups.length; i++){
					if(this.selectedGroups[i].id == group.id){
						this.selectedGroups.splice(i, 1);
						break;
					}
				}
			},
			removeApp() {
				DemonstrationsApi.delete(this.demoInfos.complexId)
					.then(response => {
						if(!response.data.error){
							this.message = {
								variant: "info",
								content: "Demonstration deleted, redirecting to demonstration list..."
							};
							this.redirectToList(1500);
						}
					})
					.catch(e => {
						console.log(e);
					});
			},
			getIconImageUrl(){
				return DemonstrationsApi.getIconImageUrl(this.demoInfos.complexId);
			},
			orderAsc(a, b){
				if ( a.name.toLowerCase() < b.name.toLowerCase() ){
					return -1;
				}
				if ( a.name.toLowerCase() > b.name.toLowerCase() ){
					return 1;
				}
				return 0;
			},
			closeChangelogModal(){
				this.showChangelogModal = false
            }
		},
	};
</script>
